import React, { Fragment } from 'react'
import '../global.css'
import styled from 'styled-components'

import NavBar from './NavBar'
import Footer from './Footer'

const LayoutContainer = styled.div`
  max-width: 900px;
  margin: 1.5em auto 0;
  position: relative;
  min-height: 80vh;
  @media (max-width: 900px) {
    margin: 1.5em 1em 0;
  }
`

class Layout extends React.Component {
  render() {
    const { title, children } = this.props

    return (
      <Fragment>
        <NavBar title={title} />
        <LayoutContainer>
          {children}
          <Footer />
        </LayoutContainer>
      </Fragment>
    )
  }
}

export default Layout
