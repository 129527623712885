import React from 'react'
import styled from 'styled-components'

const FooterContainer = styled.footer`
  display: flex;
  align-items: center;
  height: 5em;
  border-top: 1px solid #f2f2f2;
  margin-top: 2em;
  position: absolute;
  bottom: -10vh;
  width: 100%;
  @media (max-width: 930px) {
    margin: 2em 0;
    bottom: -16vh
  }
`

const Footer = () => (
  <FooterContainer>
    <p>
      © {new Date().getFullYear()},{` `}{' '}
      <a href="https://www.devmountain.com">DevMountain</a>
    </p>
  </FooterContainer>
)

export default Footer
