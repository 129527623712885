import React from 'react'
import { Link, graphql } from 'gatsby'
import styled from 'styled-components'

import Layout from '../components/Layout'
import SEO from '../components/seo'

const BlogContainer = styled.div`
  max-width: 960px;
  margin: 0 auto;
`
const InPostNav = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  h4 {
    text-align: center;
  }
  a:hover {
    color: #2aabe2;
  }
`

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark
    const siteTitle = this.props.data.site.siteMetadata.title
    const postCategory = this.props.data.markdownRemark.frontmatter.category
    const postOrder = this.props.data.markdownRemark.frontmatter.order
    const allPosts = this.props.data.allMarkdownRemark.edges
    const nextPost = allPosts.filter(post => {
      if (post.node.frontmatter.category === postCategory) {
        if (post.node.frontmatter.order === postOrder + 1) {
          return post
        }
      }
    })
    const prevPost = allPosts.filter(post => {
      if (post.node.frontmatter.category === postCategory) {
        if (post.node.frontmatter.order === postOrder - 1) {
          return post
        }
      }
    })

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title={post.frontmatter.title} description={post.excerpt} />
        <BlogContainer>
          <div
            style={{
              borderBottom: '1px solid #f2f2f2',
              display: 'inline-block',
            }}
          >
            <h1 style={{ marginBottom: 0 }}>{post.frontmatter.title}</h1>
          </div>
          <div dangerouslySetInnerHTML={{ __html: post.html }} />
          <InPostNav>
            {prevPost.length ? (
              <h4>
                <Link to={prevPost[0].node.fields.slug}>
                  ← Back to {prevPost[0].node.frontmatter.title}
                </Link>
              </h4>
            ) : (
              <span />
            )}
            {nextPost.length ? (
              <h4>
                <Link to={nextPost[0].node.fields.slug}>
                  Continue to {nextPost[0].node.frontmatter.title} →
                </Link>
              </h4>
            ) : (
              <span />
            )}
          </InPostNav>
        </BlogContainer>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      frontmatter {
        title
        order
        category
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___category], order: ASC }) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            order
            title
            category
          }
        }
      }
    }
  }
`
