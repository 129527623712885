import React, { Component } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #333;
  width: 100vw;
  padding: 0 2em;
  @media (max-width: 820px) {
    padding: 0 1em;
  }
`
const NavTitle = styled.h1`
  @media (max-width: 850px) {
    font-size: 1.3em;
  }
  a {
    text-decoration: none;
    color: #fff;
    font-weight: 700;
  }
`

const NavLinks = styled.ul`
  width: 450px;
  display: flex;
  justify-content: space-evenly;
  padding: 0;
  list-style: none;
  a {
    color: #fff;
    text-decoration: none;
  }
  @media (max-width: 820px) {
    display: none;
  }
`

const MobileMenu = styled.div`
  display: none;
  cursor: pointer;
  @media (max-width: 820px) {
    display: block;
  }
`
const Line = styled.div`
  background: #fff;
  width: 1.7em;
  height: 2px;
  margin: 0.3em 0;
`

const MobileNav = styled.div`
  height: 300px;
  background: #fff;
  position: absolute;
  left: 0;
  right: 0;
  display: ${props => (props.visible ? `block` : `none`)};
  z-index: 2;
  transition: left 0.8s;
  border-bottom: 1px solid #dadada;
  box-shadow: 0px 4px 20px 0 #ccc;
`
const MobileNavLinks = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
  width: 170px;
  top: 9.5em;
  left: 50%;
  margin-left: -100px;
  opacity: ${props => (props.visible ? 1 : 0)};
`
const MobileNavLink = styled.h4`
  margin: 0.5em 0;
`
const CloseMobileNav = styled.div`
  float: right;
  margin: 0.1em 0.5em;
  font-size: 3em;
  cursor: pointer;
`

class NavBar extends Component {
  constructor() {
    super()
    this.state = {
      showMobileNav: false,
    }
  }

  handleShowMobileNav = () => {
    this.setState(prevState => {
      return {
        showMobileNav: !prevState.showMobileNav,
      }
    })
  }
  render() {
    const { title } = this.props
    return (
      <Nav>
        <NavTitle>
          <Link to={`/`}>{title}</Link>
        </NavTitle>
        <NavLinks>
          <Link to={`/dev-page`}>
            <li>Developer Environment</li>
          </Link>
          <Link to={`/html-css-page`}>
            <li>HTML & CSS</li>
          </Link>
          <Link to={`/js-page`}>
            <li>JavaScript</li>
          </Link>
        </NavLinks>
        <MobileMenu onClick={this.handleShowMobileNav}>
          <Line />
          <Line />
          <Line />
        </MobileMenu>
        <MobileNav visible={this.state.showMobileNav}>
          <MobileNavLinks visible={this.state.showMobileNav}>
            <Link to={`/dev-page`}>
              <MobileNavLink>Developer Environment</MobileNavLink>
            </Link>
            <Link to={`/html-css-page`}>
              <MobileNavLink>HTML & CSS</MobileNavLink>
            </Link>
            <Link to={`/js-page`}>
              <MobileNavLink>JavaScript</MobileNavLink>
            </Link>
          </MobileNavLinks>
          <CloseMobileNav onClick={this.handleShowMobileNav}>x</CloseMobileNav>
        </MobileNav>
      </Nav>
    )
  }
}

export default NavBar
